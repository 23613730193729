<template>
  <div class="page">
    
    <SimpleHeader />
    
    <div class="form-recover-pwd text-center">
      <div class="card">
        <div class="card-body">
          <h1 class="h3 font-weight-normal">Atualização de dados </h1>
          <p class="text-justify mt-5">
            Olá, {{ nome }}, bem vindo(a) à atualização de dados da Bike
            Registrada.
          </p>
          <p class="text-justify">
            Estamos constantemente implementando medidas para que os dados de
            nossos clientes estejam sempre atualizados e 100% seguros. Por isso
            pedimos para que você confirme seus dados abaixo e clique em
            atualizar.
          </p>
      <div class="text-left mb-3"><b>Atualmente os dados da sua conta estão registrados em nossa base conforme abaixo. <br> Você pode editá-los ou  mante-los se for o caso.</b></div>
        <b-alert show variant="warning" class="text-justify">
         <strong>Somente será possível o acesso a qualquer serviço Bike Registrada caso os dados estejam preenchidos corretamente em todos
          os campos obrigatórios. Os dados devem ser única e exclusivamente do cliente, 
          dados de terceiros não serão aceitos.
        </strong> 
        </b-alert>
          <ValidationObserver v-slot="{ invalid }">
            <form data-test-id="">
              <div class="mt-3">
                <div class="row">
                  <div class="form-group col-sm-12 col-md-6">
                    <label
                      >Digite seu nome completo
                      <small>(obrigatório)</small></label
                    >
                    <ValidationProvider
                      name="nome"
                      rules="required|nome_completo"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :class="classes"
                        v-model="nome"
                        autocomplete="nope"
                        maxlength="100"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-sm-12 col-md-6">
                    <label>Nascimento <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="nascimento"
                      rules="required|data_nascimento|data_maior_idade"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="tel"
                        class="form-control"
                        :class="classes"
                        v-model="nascimento"
                        v-mask="'##/##/####'"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-12 col-md-6">
                    <label
                      >Seu celular whatsApp <small>(obrigatório)</small>
                    </label>
                    <ValidationProvider
                      name="telefone"
                      rules="required|validacao_telefone"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="tel"
                        class="form-control"
                        :class="classes"
                        v-model="telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        data-test-id="input-telefone"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group  col-sm-12 col-md-6">
                    <label>CPF <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="CPF"
                      rules="required|cpf"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="tel"
                        maxlength="20"
                        class="form-control"
                        :class="classes"
                        v-model="cpf"
                        v-mask="'###.###.###-##'"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-4 col-md-3">
                    <label>Estado <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="estado"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        class="form-control"
                        v-model="uf"
                        :class="classes"
                        data-test-id="input-estado"
                      >
                        <option :value="undefined">Selecione...</option>
                        <option>AC</option>
                        <option>AL</option>
                        <option>AM</option>
                        <option>AP</option>
                        <option>BA</option>
                        <option>CE</option>
                        <option>DF</option>
                        <option>ES</option>
                        <option>GO</option>
                        <option>MA</option>
                        <option>MG</option>
                        <option>MS</option>
                        <option>MT</option>
                        <option>PA</option>
                        <option>PB</option>
                        <option>PE</option>
                        <option>PI</option>
                        <option>PR</option>
                        <option>RJ</option>
                        <option>RN</option>
                        <option>RO</option>
                        <option>RR</option>
                        <option>RS</option>
                        <option>SC</option>
                        <option>SE</option>
                        <option>SP</option>
                        <option>TO</option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-sm-8 col-md-4">
                    <label>Cidade <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="cidade"
                      rules="required|min:3"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :class="classes"
                        v-model="cidade"
                        autocomplete="nope"
                        maxlength="50"
                        data-test-id="input-cidade"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-sm-12 col-md-5">
                    <label>Confirme seu e-mail </label>
                    <ValidationProvider
                      name="email"
                      rules="required|email"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        readonly
                        type="text"
                        class="form-control"
                        :class="classes"
                        v-model="email"
                        maxlength="100"
                        data-test-id="input-email"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="text-right mt-4">
                <button
                  class="btn btn-success sucesso-botao-desktop"
                  data-test-id="btn-continuar"
                  @click.prevent="onClickConfirm()"
                  :disabled="invalid"
                >
                  Atualizar meus dados
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <router-link to="/" class="mt-3 d-block"
        >Voltar para a tela inicial</router-link
      >
      <div v-if="loading"><Loading :criandoSeguro="salvarDados" /></div>
    </div>
    
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import config from "../../config";
import Auth from "../../auth";
import captureError from "../../helpers/captureError";
import SimpleHeader from "../../components/SimpleHeader";
import Loading from "../../components/Usuario/Loading.vue";
// import ModalNumSerie from "../components/NovaJornada/ModalNumSerie.vue";


export default {
  components: {
    SimpleHeader,Loading
  },
  data() {
    return {
      loading: false,
      senha: "",
      salvarDados:false,
      confirmarSenha: "",
      token: "",
      nome: "",
      email: "",
      cpf: "",
      nascimento: "",
      telefone:"",
      uf: undefined,
      cidade:""
    };
  },
  async created() {
     window.localStorage.clear();
    this.token = this.$route.query.p;
    if (!this.token) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "Este link de atualizacao de dados está expirado. Por favor, solicite um novo link.",
      });
      Auth.logout();
      this.$router.replace("/login");
    } else {
      this.obterDados(this.token);
    }
  },
  watch: {
    cpf: function(newValue, oldValue) {
      if (newValue.replace(/\D/g, '') != oldValue.replace(/\D/g, '') && newValue.replace(/\D/g, '').length>=11) {
        this.verificarPodeContinuar();
      }
    },
    cidade: function(value) {
     this.cidade=value.toUpperCase();
    },
  },
  methods: {
    async obterDados(token) {
      try {
        this.loading = true;
        this.salvarDados= true;
        var response = await axios.get(
          `${config.API_URLV2}/auth/obterDadosAtualizar?token=${token}`
        );
        this.nome = response.data.nome;
        this.email = response.data.email;
        this.loading = false;
        this.salvarDados= false;
      } catch (e) {
        //captureError(e);
        this.loading = false;
        this.salvarDados= false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível a busca da baixa. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },

    async verificarPodeContinuar() {
      try {
        this.loading  = true;
         this.salvarDados= true;
        var response = await axios.get(
          `${config.API_URLV2}/cliente/verificarExisteClienteComCpfEmail?email=${this.email}&cpf=${this.cpf}`
        );
        if (response.data.podeContinuar) {
          this.loading  = false;
           this.salvarDados= false;
          return true;
        } else {
          this.loading  = false;
           this.salvarDados= false;
          if (response.data.cliente) {
            Swal.fire({
              title: "Aviso!",
              icon: "info",
              html: `Olá, você está tentando usar um e-mail que já está vinculado a outro CPF: </b>  ${response.data.cliente}`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
              confirmButtonAriaLabel: "Eu entendi!",
            });
          } else {
            Swal.fire({
              title: "Aviso!",
              icon: "info",
              html: `O CPF informado já está vinculado a uma conta</b>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
              confirmButtonAriaLabel: "Eu entendi!",
            });
          }
          return false;
        }
      } catch (error) {
        this.loading  = false;
         this.salvarDados= false;
        Swal.fire({
          title: "Não foi possivel prosseguir com o seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async onClickConfirm() {
      this.loading = true;
      let variavel = await this.verificarPodeContinuar();
      if(!variavel) return
      try {
        var data = {
          nome:this.nome,
          uf:this.uf,
          cidade:this.cidade,
          telefone: this.telefone,
          email:this.email,
          dataNascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
          cpf: this.cpf,
        };
        var response = await axios.post(
          `${config.API_URLV2}/auth/atualizarDados`,
          data
        );

        await Swal.fire({
          icon: "success",
          title: "Dados atualizados com sucesso",
        });

        //Loga automaticamente e leva para a home
        Auth.setUserInfo(response.data);
        this.$router.replace("/cliente");
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
          error?.response?.data?.error,
        });
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  text-align: left;
}
.page {
  background: #f2f2f2;
  min-height: 100vh;
}
.form-recover-pwd {
  width: 100%;
  max-width: 1000px;
  padding: 16px;
  margin: 0 auto;
  margin-top: 40px;
}
.sucesso-botao-desktop {
  width: 180px;
  border-radius: 10px;
  padding: 11px;
  margin-bottom: 10px;
}
</style>
