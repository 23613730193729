var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('SimpleHeader'),_c('div',{staticClass:"form-recover-pwd text-center"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h1',{staticClass:"h3 font-weight-normal"},[_vm._v("Atualização de dados ")]),_c('p',{staticClass:"text-justify mt-5"},[_vm._v(" Olá, "+_vm._s(_vm.nome)+", bem vindo(a) à atualização de dados da Bike Registrada. ")]),_c('p',{staticClass:"text-justify"},[_vm._v(" Estamos constantemente implementando medidas para que os dados de nossos clientes estejam sempre atualizados e 100% seguros. Por isso pedimos para que você confirme seus dados abaixo e clique em atualizar. ")]),_vm._m(0),_c('b-alert',{staticClass:"text-justify",attrs:{"show":"","variant":"warning"}},[_c('strong',[_vm._v("Somente será possível o acesso a qualquer serviço Bike Registrada caso os dados estejam preenchidos corretamente em todos os campos obrigatórios. Os dados devem ser única e exclusivamente do cliente, dados de terceiros não serão aceitos. ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"data-test-id":""}},[_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-sm-12 col-md-6"},[_c('label',[_vm._v("Digite seu nome completo "),_c('small',[_vm._v("(obrigatório)")])]),_c('ValidationProvider',{attrs:{"name":"nome","rules":"required|nome_completo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nome),expression:"nome"}],staticClass:"form-control",class:classes,attrs:{"type":"text","autocomplete":"nope","maxlength":"100"},domProps:{"value":(_vm.nome)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nome=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-sm-12 col-md-6"},[_c('label',[_vm._v("Nascimento "),_c('small',[_vm._v("(obrigatório)")])]),_c('ValidationProvider',{attrs:{"name":"nascimento","rules":"required|data_nascimento|data_maior_idade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nascimento),expression:"nascimento"},{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",class:classes,attrs:{"type":"tel","autocomplete":"nope"},domProps:{"value":(_vm.nascimento)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nascimento=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-sm-12 col-md-6"},[_c('label',[_vm._v("Seu celular whatsApp "),_c('small',[_vm._v("(obrigatório)")])]),_c('ValidationProvider',{attrs:{"name":"telefone","rules":"required|validacao_telefone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.telefone),expression:"telefone"},{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],staticClass:"form-control",class:classes,attrs:{"type":"tel","data-test-id":"input-telefone","autocomplete":"nope"},domProps:{"value":(_vm.telefone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.telefone=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-sm-12 col-md-6"},[_c('label',[_vm._v("CPF "),_c('small',[_vm._v("(obrigatório)")])]),_c('ValidationProvider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cpf),expression:"cpf"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticClass:"form-control",class:classes,attrs:{"type":"tel","maxlength":"20","autocomplete":"nope"},domProps:{"value":(_vm.cpf)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cpf=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-sm-4 col-md-3"},[_c('label',[_vm._v("Estado "),_c('small',[_vm._v("(obrigatório)")])]),_c('ValidationProvider',{attrs:{"name":"estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.uf),expression:"uf"}],staticClass:"form-control",class:classes,attrs:{"data-test-id":"input-estado"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.uf=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":undefined}},[_vm._v("Selecione...")]),_c('option',[_vm._v("AC")]),_c('option',[_vm._v("AL")]),_c('option',[_vm._v("AM")]),_c('option',[_vm._v("AP")]),_c('option',[_vm._v("BA")]),_c('option',[_vm._v("CE")]),_c('option',[_vm._v("DF")]),_c('option',[_vm._v("ES")]),_c('option',[_vm._v("GO")]),_c('option',[_vm._v("MA")]),_c('option',[_vm._v("MG")]),_c('option',[_vm._v("MS")]),_c('option',[_vm._v("MT")]),_c('option',[_vm._v("PA")]),_c('option',[_vm._v("PB")]),_c('option',[_vm._v("PE")]),_c('option',[_vm._v("PI")]),_c('option',[_vm._v("PR")]),_c('option',[_vm._v("RJ")]),_c('option',[_vm._v("RN")]),_c('option',[_vm._v("RO")]),_c('option',[_vm._v("RR")]),_c('option',[_vm._v("RS")]),_c('option',[_vm._v("SC")]),_c('option',[_vm._v("SE")]),_c('option',[_vm._v("SP")]),_c('option',[_vm._v("TO")])]),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-sm-8 col-md-4"},[_c('label',[_vm._v("Cidade "),_c('small',[_vm._v("(obrigatório)")])]),_c('ValidationProvider',{attrs:{"name":"cidade","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cidade),expression:"cidade"}],staticClass:"form-control",class:classes,attrs:{"type":"text","autocomplete":"nope","maxlength":"50","data-test-id":"input-cidade"},domProps:{"value":(_vm.cidade)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cidade=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-sm-12 col-md-5"},[_c('label',[_vm._v("Confirme seu e-mail ")]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:classes,attrs:{"readonly":"","type":"text","maxlength":"100","data-test-id":"input-email","autocomplete":"nope"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"text-right mt-4"},[_c('button',{staticClass:"btn btn-success sucesso-botao-desktop",attrs:{"data-test-id":"btn-continuar","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.onClickConfirm()}}},[_vm._v(" Atualizar meus dados ")])])])]}}])})],1)]),_c('router-link',{staticClass:"mt-3 d-block",attrs:{"to":"/"}},[_vm._v("Voltar para a tela inicial")]),(_vm.loading)?_c('div',[_c('Loading',{attrs:{"criandoSeguro":_vm.salvarDados}})],1):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-left mb-3"},[_c('b',[_vm._v("Atualmente os dados da sua conta estão registrados em nossa base conforme abaixo. "),_c('br'),_vm._v(" Você pode editá-los ou mante-los se for o caso.")])])}]

export { render, staticRenderFns }